import { SalonListComponent } from './../components/salon-list/salon-list.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { NgxNotyfModule } from 'ngx-notyf';

import { NavbarComponent } from '../components/navbar/navbar.component';
import { SearchComponent } from '../components/search/search.component';
import { HeroSliderComponent } from '../components/hero-slider/hero-slider.component';
import { FavouritesSliderComponent } from '../components/favourites-slider/favourites-slider.component';
import { ServicesListComponent } from '../components/services-list/services-list.component';
import { ServicesListItemComponent } from '../components/services-list-item/services-list-item.component';
import { ClickPreventDefaultDirective } from '../directives/click-prevent-default.directive';
import { FooterComponent } from '../components/footer/footer.component';
import { EnvironmentServiceProvider } from '../services/environment/environment.service.provider';
import { ApiServiceProvider } from '../services/api/api.service.provider';
import { TokenInterceptor } from '../interceptors/token.interceptor';
import { StarRatingComponent } from '../components/star-rating/star-rating.component';
import { CategoryListComponent } from '../components/category-list/category-list.component';
import { MapComponent } from '../components/map/map.component';
import { CurrencyPipe } from '../pipes/currency.pipe';
import { SafeHtmlPipe } from '../pipes/safe-html.pipe';

@NgModule({
  declarations: [
    NavbarComponent,
    SearchComponent,
    HeroSliderComponent,
    FavouritesSliderComponent,
    ServicesListComponent,
    ServicesListItemComponent,
    FooterComponent,
    StarRatingComponent,
    CategoryListComponent,
    MapComponent,
    SalonListComponent,
    ClickPreventDefaultDirective,
    SafeHtmlPipe,
    CurrencyPipe,
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    SlickCarouselModule,
    LeafletModule,
    BsDatepickerModule,
    ButtonsModule,
    NgxNotyfModule,
    AccordionModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    IonicModule,
    NavbarComponent,
    SearchComponent,
    HeroSliderComponent,
    FavouritesSliderComponent,
    ServicesListComponent,
    ServicesListItemComponent,
    FooterComponent,
    StarRatingComponent,
    CategoryListComponent,
    MapComponent,
    SalonListComponent,
    ClickPreventDefaultDirective,
    ReactiveFormsModule,
    SafeHtmlPipe,
    CurrencyPipe,
    BsDatepickerModule,
    ButtonsModule,
    AccordionModule
  ],
  providers: [
    EnvironmentServiceProvider,
    ApiServiceProvider,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    SafeHtmlPipe,
    CurrencyPipe,
  ]
})
export class SharedModule { }
