import { CartService } from './../../services/cart/cart.service';
import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AlertController, NavController } from '@ionic/angular';
import { filter } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {

  offcanvasOpen = false;
  menuShown = false;
  loggedIn = false;
  currentRoute: string;

  cart = [];
  cart_empty = true;

  @Input() mobileHeader = true;

  constructor(
    private alertController: AlertController,
    public auth: AuthService,
    private navController: NavController,
    private router: Router,
    private cartService: CartService,
  ) { }

  ngOnInit() {
    this.auth.loggedIn$.subscribe((isLoggedIn: boolean) => {
      this.loggedIn = isLoggedIn;
    });

    this.router.events
    // Here we are passing all events through the pipe. The filter will only return NavigationEnd events
    .pipe(filter(e => e instanceof NavigationEnd))
    // Get the current route from the event
    .subscribe((event: NavigationEnd) => {
      this.currentRoute = event.url;
    });

    // listening for changes to the cart
    this.cartService.cart$.subscribe(cart => {
      this.cart = cart;

      if (this.cart.length > 0) {
        this.cart_empty = false;
      }
    })
  }

  toggleOffcanvasNav() {
    this.offcanvasOpen = !this.offcanvasOpen;
  }

  async logout() {
    this.auth.logout();

    const alert = await this.alertController.create({
      header: 'Success',
      message: 'You have successfully been logged out!',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.navController.navigateRoot('/');
          }
        }
      ]
    });

    await alert.present();
  }

  goBack() {
    this.navController.back();
  }
}
