import { TabsyncService } from './../tabsync/tabsync.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { EnvironmentService } from '../environment/environment.service';
import * as _moment from 'moment';
import { shareReplay, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

const moment = _moment;

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  loggedInMessage = 'logged in';
  loggedOutMessage = 'logged out';

  baseUrl: string;
  authEndpoint = '/oauth/token';
  options: any;

  loggedIn$: Observable<boolean>;

  private loggedInSubject: BehaviorSubject<boolean>;

  constructor(
    private api: ApiService,
    private environment: EnvironmentService,
    private tabsync: TabsyncService,
  ) {
    this.loggedInSubject = new BehaviorSubject<boolean>(this.getAccessToken() ? true : false);
    this.loggedIn$ = this.loggedInSubject.asObservable();

    console.log('this has ran');

    console.log({
      subject: this.loggedInSubject,
      loggedIn: this.loggedIn$,
    })
  }

  getAccessToken() {
    return localStorage.getItem('access_token');
  }

  getRefreshToken() {
    return localStorage.getItem('refresh_token');
  }

  getExpiration() {
    const expiration = localStorage.getItem('expires_in');
    const expires_at = JSON.parse(expiration);

    return moment(expires_at);
  }

  getTokenType() {
    return localStorage.getItem('token_type');
  }

  isLoggedIn() {
    return moment().isBefore(this.getExpiration());
  }

  login(username: string, password: string) {
    console.log('%c URL being called: ' + (this.baseUrl + this.authEndpoint), 'color:red;');

    return this.api.post(this.authEndpoint, {
      grant_type: 'password',
      client_id: this.environment.api.client_id,
      client_secret: this.environment.api.client_secret,
      username: username,
      password: password,
      scope: ''
    }).pipe(
      tap(res => {
        this.setSession(res);
      }),
      shareReplay()
    );
  }

  logout() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('token_type');
    localStorage.removeItem('expires_in');

    this.tabsync.postMessage('logged out');

    this.loggedInSubject.next(false);
  }

  setSession(oauthResponse) {
    const expires_at = moment().add(oauthResponse.expires_in, 'second');

    localStorage.setItem('access_token', oauthResponse.access_token);
    localStorage.setItem('refresh_token', oauthResponse.refresh_token);
    localStorage.setItem('token_type', oauthResponse.token_type);
    localStorage.setItem('expires_in', JSON.stringify(expires_at));

    this.tabsync.postMessage('logged in');

    this.loggedInSubject.next(true);
  }
}
