import { NgxNotyfService } from 'ngx-notyf';
import { Injectable } from '@angular/core';
import { INotyfOptions, Notyf, NotyfNotification } from 'notyf';

@Injectable({
  providedIn: 'root'
})
export class NotyfService {

  private notyf: Notyf;
  private options: Partial<INotyfOptions> = {
    position: {
      x: "right",
      y: "top",
    },
    dismissible: true,
    duration: 5 * 1000, // 5 seconds
  };;

  constructor(
    private _notyf: NgxNotyfService,
  ) { 
    this.notyf = this._notyf.setOptions(this.options);
  }

  newInstance(options: Partial<INotyfOptions>) {
    return this._notyf.setOptions(options);
  }

  error(message: string|Partial<INotyfOptions>) {
    this.notyf.error(message)
  }

  success(message: string|Partial<INotyfOptions>) {
    this.notyf.success(message)
  }

  dismiss(notification: NotyfNotification) {
    this.notyf.dismiss(notification);
  }
}
