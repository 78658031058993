import { Component, Input, OnInit } from '@angular/core';
import { Salon } from 'src/app/interfaces/salon';

@Component({
  selector: 'app-salon-list',
  templateUrl: './salon-list.component.html',
  styleUrls: ['./salon-list.component.scss'],
})
export class SalonListComponent implements OnInit {

  @Input() salons: Salon[];

  constructor() { }

  ngOnInit() {
    // console.log({salons: this.salons});
  }

}
