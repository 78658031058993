import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '../environment/environment.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private headers: any;
  private base_url: string;

  constructor(
    private http: HttpClient,
    private environment: EnvironmentService,
  ) {
    let headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    };

    this.headers = new HttpHeaders(headers);
    this.base_url = this.environment.api.url;
  }

  get(endpoint, params = {}) {
    if (endpoint.charAt(0) !== '/') {
      endpoint = '/' + endpoint;
    }    

    const full_url = this.base_url + endpoint;

    return this.http.get(full_url, {
      headers: this.headers,
      params
    });
  }
  
  post(endpoint, params = {}) {
    if (endpoint.charAt(0) !== '/') {
      endpoint = '/' + endpoint;
    }

    const full_url = this.base_url + endpoint;

    return this.http.post(full_url, params, {
      headers: this.headers
    });
  }
}
