import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-favourites-slider',
  templateUrl: './favourites-slider.component.html',
  styleUrls: ['./favourites-slider.component.scss'],
})
export class FavouritesSliderComponent implements OnInit {

  favouritesSlider = [
    {
      img_url: 'assets/images/salon-interior.jpg',
      text: 'Favourite Service 1',
    },
    {
      img_url: 'assets/images/salon-interior.jpg',
      text: 'Favourite Service 2',
    },
    {
      img_url: 'assets/images/salon-interior.jpg',
      text: 'Favourite Service 3',
    },
    {
      img_url: 'assets/images/salon-interior.jpg',
      text: 'Favourite Service 4',
    },
    {
      img_url: 'assets/images/salon-interior.jpg',
      text: 'Favourite Service 5',
    },
    {
      img_url: 'assets/images/salon-interior.jpg',
      text: 'Favourite Service 6',
    },
    {
      img_url: 'assets/images/salon-interior.jpg',
      text: 'Favourite Service 7',
    },
    {
      img_url: 'assets/images/salon-interior.jpg',
      text: 'Favourite Service 8',
    }
  ];
  
  slickConfig = {
    infinite: true,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 1,
    swipeToSlide: true,
    autoplay: true,
    autoplaySpeed: 5 * 1000, // 5 seconds
    centerMode: true,
    responsive: [
      {
          breakpoint: 1100,
          settings: {
              slidesToShow: 3,
          }
      },
      {
          breakpoint: 768,
          settings: {
              slidesToShow: 2,
          }
      },
      {
          breakpoint: 600,
          settings: {
              slidesToShow: 2,
              centerMode: false,
              dots: true
          }
      },
      {
          breakpoint: 480,
          settings: {
              slidesToShow: 1,
              centerMode: false,
              dots: true, 
              arrows: true
          }
      }
    ]
  };

  constructor() { }

  ngOnInit() {}

  slickInit(e) {
    // console.log('slick initialized');
  }

  breakpoint(e) {
    // console.log('breakpoint');
  }

  afterChange(e) {
    // console.log('afterChange');
  }

  beforeChange(e) {
    // console.log('beforeChange');
  }
}
