import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NotyfService } from 'src/app/services/notyf/notyf.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {

  el: HTMLElement;
  input: HTMLInputElement;
  parent: HTMLElement;

  constructor(
    private http: HttpClient,
    private notyf: NotyfService,
  ) {}

  ngOnInit() {}

  getLocation(el: HTMLElement) {
    this.el = el;
    this.parent = el.closest('.input-group');
    this.input = this.parent.querySelector('.form-control');

    navigator.geolocation.getCurrentPosition(res => {
      this.http.get(`https://api.postcodes.io/postcodes?lat=${res.coords.latitude}&lon=${res.coords.longitude}&limit=1`).subscribe(response => {
        if (response['result'] && response['result'].length > 0) {
          const data = response['result'][0];

          this.input.value = `${data['admin_ward']}, ${data['admin_district']}, ${data['admin_county']}, ${data['postcode']}`;
        } else {
          this.notyf.error('There was an error fetching your location. Please enter your location manually.');
        }
      });
    });
  }
}
