import { Component, Input, OnInit } from '@angular/core';
import { AccordionConfig } from 'ngx-bootstrap/accordion';
import { Category } from 'src/app/interfaces/category';
import { Service } from 'src/app/interfaces/service';
import { ApiService } from 'src/app/services/api/api.service';

export function getAccordionConfig(): AccordionConfig {
  return Object.assign(new AccordionConfig(), { closeOthers: true });
}

@Component({
  selector: 'app-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.scss'],
  providers: [{provide: AccordionConfig, useFactory: getAccordionConfig}]
})
export class CategoryListComponent implements OnInit {

  activeServices: Service[] = [];
  servicesByCategory: Category[] = [];

  selectedService: any; 

  @Input() categories: Category[];
  @Input() salon_slug: string;

  mobileSelect: number = 0;

  constructor(
    private api: ApiService,
  ) {}

  ngOnInit() {
    if (this.categories) {
      this.prepareData(this.categories);
    } else {
      this.api.get('/salons/' + this.salon_slug).subscribe(res => {
        if (res['success'] === true) {
          this.prepareData(res['data']['categories']);
        }
      });
    }
  }

  prepareData(categories: Category[]) {
    this.servicesByCategory = categories;
    this.activeServices = this.servicesByCategory[0].services;

    console.log({activeServices: this.activeServices});
  }

  changeServices(category: {services: []}, element: HTMLElement, index: number) {
    this.activeServices = category.services;
    this.mobileSelect = index;

    element.closest('.nav.nav-pills').querySelector('.active').classList.remove('active');
    element.classList.add('active');
  }

  changeServicesMobile() {
    // type-cast the response of querySelector to HTMLElement - otherwise angular/node throws an error on compile 
    const el = <HTMLElement>document.querySelector('a[data-index="' + this.mobileSelect + '"]');

    el.click();
  }

  segmentChanged(event: any) {
    this.activeServices = this.servicesByCategory[event.detail.value].services;
  }
}
