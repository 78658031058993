import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {

  public production = true;
  public api = {
    url: '',
    client_id: 0,
    client_secret: '',
    version: 'v1',
  };
  public test = '';

}
