import { HttpClient } from '@angular/common/http';
import { EnvironmentService } from '../environment/environment.service';
import { ApiService } from './api.service'

export const ApiServiceFactory = (http: HttpClient, env: EnvironmentService) => {
    return new ApiService(http, env);
}

export const ApiServiceProvider = {
    provide: ApiService,
    useFactory: ApiServiceFactory,
    deps: [
        HttpClient,
        EnvironmentService,
    ],
}