import { Component, OnInit } from '@angular/core';
// declare 'leaflet' from 'leaflet-providers';
import * as L from 'leaflet';
import { tileLayer, map, latLng } from 'leaflet';
// require('leaflet-providers');

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit {
  private map;

  options = {
    layers: [
      tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { 
        maxZoom: 18, 
        attribution: '...' 
      })
    ],
    zoom: 15,
    center: latLng([
      54.1161583804825, 
      -3.2336465756292663
    ])
  }

  constructor() {
    console.log({
      L
    })
  }

  ngOnInit() {}
  
  ngAfterViewInit() {
    this.initMap();
  }

  private initMap() {
    this.map = L.map('map', {
      center: [
        54.1161583804825, 
        -3.2336465756292663
      ],
      zoom: 15,
    });

    const tiles = L.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { 
      maxZoom: 18, 
      attribution: '...' 
    });

    tiles.addTo(this.map);

    // this is needed or the map sometimes doesn't load all tiles until you move/zoom
    setTimeout(() => {
      this.map.invalidateSize();
    }, 500);
  }
}
