import { EnvironmentService } from './environment.service'

export const EnvironmentServiceFactory = () => {
    const environment = new EnvironmentService;

    console.log({window});

    const browserWindow = window || {};
    const browserWindowEnviroment = browserWindow['__env'] || {};

    for (const key in browserWindowEnviroment) {
        if (browserWindowEnviroment.hasOwnProperty(key)) {
            environment[key] = browserWindowEnviroment[key];
        }
    }

    return environment;
}

export const EnvironmentServiceProvider = {
    provide: EnvironmentService,
    useFactory: EnvironmentServiceFactory,
    deps: [],
}