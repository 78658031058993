import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AlertController, NavController } from '@ionic/angular';
import { AuthService } from '../services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class GuestGuard implements CanActivate {
  constructor(
    private alertController: AlertController,
    private navController: NavController,
    private router: Router,
    private auth: AuthService,
  ) { }

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.auth.getAccessToken()) { 
      const alert = await this.alertController.create({
        header: 'Error',
        message: 'You are already logged in!',
        buttons: [
          {
            text: 'OK',
            handler: () => {
              // check to see if there is any history to navigate back to
              if (this.router.navigated) {
                // navigate back to the previous page
                this.navController.back();
              } else {
                // login page accessed directly, redirect to home page 
                this.navController.navigateRoot('/');
              }
            }
          }
        ],
        mode: 'md'
      });
  
      await alert.present();
      
      return false;
    }

    return true;
  }
}
