import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  cart$: Observable<any>;
  private cartSubject: BehaviorSubject<any>;

  constructor() {
    this._getCart();
  }

  addBooking(service_id: number, service_name: string, employee_id: number, employee_name: string, date: string, time: string, price: number|string, salon_id: number, salon_name: string) {
    const booking = {
      service_id,
      service_name,
      employee_id,
      employee_name,
      date,
      time,
      price,
      added: new Date(),
      salon_id,
      salon_name,
    };

    const cart = this.cartSubject.getValue();

    cart.push(booking);
    localStorage.setItem('cart', JSON.stringify(cart));

    this.cartSubject.next(cart);

    return cart;
  }

  private _getCart() {
    this.cartSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('cart')) || []);
    this.cart$ = this.cartSubject.asObservable();
  } 

  getCart() {
    const cart = this.cartSubject.getValue();

    if (cart.length === 0) {
      this._getCart();
    }

    return cart;
  }

  removeItem(index: number) {
    const cart = this.cartSubject.getValue();

    if (cart[index]) {
      cart.splice(index, 1);
    }

    localStorage.setItem('cart', JSON.stringify(cart));

    this.cartSubject.next(cart);

    return cart;
  }

  clearCart() {
    const cart = [];

    localStorage.setItem('cart', JSON.stringify(cart));

    this.cartSubject.next(cart);

    return cart;
  }
}
